<template>
  <div class="out">
    <Top />
    <div style="background-color: #f6f6f8">
      <div class="nav"></div>
      <div class="modole" style="justify-content: space-between">
        <div style="display: flex">
          <div class="stick"></div>
          <div class="title">{{ lang.v.starcard }}</div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.14rem;
          "
          @click="gointelligent"
        >
          <div class="more_more">{{ lang.v.more }}</div>
          <div class="more_img">
            <img src="~@/assets/img/more_img.png" alt="" />
          </div>
        </div>
      </div>
      <div class="xingka">
        {{ lang.v.describe }}
      </div>
      <div style="background-color: #f6f6f8">
        <div class="main">
          <div class="become_login">
            <div
              class="type_login"
              v-for="role in types_login"
              :key="role.type"
            >
              <div class="type_top"></div>
              <div class="card_login">
                <div class="card_logo">
                  <img
                    :src="
                      role.type == 1
                        ? card_logo.imageSrc
                        : card_logo.otherImageSrc
                    "
                    alt=""
                  />
                </div>
                <div
                  class="card_name"
                  :style="{ color: role.type == 1 ? '#5EB15C' : '#ED931E' }"
                >
                  {{ role.name }}
                </div>
              </div>
              <div
                class="multiple"
                style="display: none"
                :innerText="role.type == 1 ? lang.v.banQuan1 : lang.v.banQuan2"
              ></div>
              <div class="Unlock" @click="gopay(role)">
                <div>
                  <img
                    style="width: 100%; height: 0.3rem; border-radius: 0.06rem"
                    :src="role.type == 1 ? imgs.imgs1 : imgs.imgs2"
                    alt=""
                  />
                </div>
                <div
                  style="margin-top: -0.29rem"
                  :innerHTML="
                    role.type == 1
                      ? lang.v.unit + text1 + unlock
                      : lang.v.unit + text2 + unlock
                  "
                ></div>
              </div>
              <div class="task" v-for="task in role.equityList" :key="task">
                <div class="completion">
                  <div
                    class="points"
                    :style="
                      role.type == 1
                        ? 'background-color: #5EB15C'
                        : 'background-color: #ED931E'
                    "
                  ></div>
                  <div class="task_describe">{{ task }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="new_box">
        <div class="new_name">{{ lang.v.starReward }}</div>
        <div class="new_contant">
          <div class="new_text">
            {{ lang.v.text1 }}
          </div>
          <div class="new_text">
            {{ lang.v.text2 }}
          </div>
          <div class="new_text">
            {{ lang.v.text3 }}
          </div>
        </div>
      </div>
      <div class="new_box">
        <div class="new_name">{{ lang.v.starClu }}</div>
        <div class="new_contant">
          <div class="new_text">
            {{ lang.v.text4 }}
          </div>
        </div>
      </div>
      <div class="modole" style="justify-content: space-between">
        <div style="display: flex">
          <div class="stick"></div>
          <div class="title">{{ lang.v.copyright }}</div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.14rem;
          "
          @click="gocopyright"
        >
          <div class="more_more">{{ lang.v.more }}</div>
          <div class="more_img">
            <img src="~@/assets/img/more_img.png" alt="" />
          </div>
        </div>
      </div>
      <div class="main_body">
        <div v-for="role in projects.list" :key="role.id">
          <div class="project">
            <div class="project_top">
              <div class="image-wrapper">
                <div class="image">
                  <img :src="role.content" alt="" />
                </div>
              </div>
            </div>
            <div
              style="display: flex; justify-content: space-between"
              class="name_time"
            >
              <div class="project_name" style="margin-left: 0.14rem">
                {{ role.name }}
              </div>
              <div class="project_time" style="margin-right: 0.14rem">
                {{ updateCountdown(role.endTime) }}
              </div>
            </div>
            <div class="project_info">
              <table>
                <tr class="first_info">
                  <td>{{ role.income * 365 }}%</td>
                  <td>{{ role.userMinAmount }}</td>
                  <td>
                    <p class="text">
                      {{ role.progressMoney }}/{{ role.totalMoney }}
                    </p>
                  </td>
                </tr>
                <tr class="second_info">
                  <td>{{ lang.v.td1 }}</td>
                  <td>{{ lang.v.td2 }}</td>
                  <td>{{ lang.v.td3 }}</td>
                </tr>
              </table>
            </div>
            <div class="tishi">
              {{ lang.v.warning }}
            </div>
            <div class="project_foot">
              <div class="project_schedule">
                <div class="schedule" style="width: 100%">
                  <van-progress
                    style="width: 100%"
                    :percentage="role.progress"
                    :show-pivot="false"
                    stroke-width="0.09rem"
                  />
                </div>
                <div class="src_schedule">
                  {{ lang.v.schedule }}: {{ role.progress }}%
                </div>
              </div>
              <div @click="isshow(role)" class="project_check">
                {{ lang.v.buynow }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modole">
        <div class="stick"></div>
        <div class="title">{{ lang.v.download }}</div>
      </div>
      <div class="download">
        <div class="box_download" @click.stop="downloadApp_2()">
          <div class="left_logo">
            <div class="Android">
              <img src="~@/assets/img/Android_white.png" alt="" />
            </div>
          </div>
          <div class="right_text">
            <div class="source">{{ lang.v.go }}</div>
            <div class="type">{{ lang.v.android }}</div>
          </div>
        </div>
        <div
          class="box_download"
          style="margin-left: 0.11rem"
          @click.stop="downloadApp()"
        >
          <div class="left_logo">
            <div class="Android">
              <img src="~@/assets/img/Apple_white.png" alt="" />
            </div>
          </div>
          <div class="right_text">
            <div class="source">{{ lang.v.go }}</div>
            <div class="type">{{ lang.v.apple }}</div>
          </div>
        </div>
      </div>
      <div class="modole">
        <div class="stick"></div>
        <div class="title">{{ lang.v.Characteristic }}</div>
      </div>
      <div class="characteristic">
        <div class="box" v-for="role in privileges" :key="role.id">
          <div class="characteristic_logo"><img :src="role.logo" alt="" /></div>
          <div class="characteristic_name">{{ role.name }}</div>
          <div class="characteristic_name">{{ role.describe }}</div>
        </div>
      </div>
      <!-- 弹窗 -->
      <InvestmentOverlay ref="PayDialog" :payCard="payCard" />
      <Invite ref="inviteRef" />
    </div>
    <Tabbar />
  </div>
</template>
<script setup>
import { lang } from '@/lang';
import { clearLocalUser } from '@/utils/memory';
import { onMounted, reactive, ref } from 'vue';
import { updateCountdown } from '@/utils/endCountdown';
import InvestmentOverlay from '@/components/InvestmentOverlay/index.vue';
import {
  card_info,
  card_pay,
  invest,
  inviteInfo,
  personal,
} from '@/api/fetcher';
import Invite from '@/components/Invite/index.vue';
import Top from '@/components/Top/top.vue';
import Tabbar from '@/components/Tabbar/tabbar.vue';
import router from '@/router';
import 'vant/lib/toast/index.css';
import dataCenter from '@/dataCenter';
const unlock = lang.v.unlock;
const goldId = ref('1');
const text1 = ref('');
const imgs = ref({
  imgs1: require('@/assets/img/ordinary_pay.png'),
  imgs2: require('@/assets/img/senior_pay.png'),
});
const text2 = ref('');
const show_dia = reactive({ inited: false, show: false });
const projects = ref([]);
const card_logo = reactive({
  imageSrc: require('@/assets/img/ordinary.png'),
  otherImageSrc: require('@/assets/img/senior.png'),
});
const gopay = e => {
  sessionStorage.setItem('intelligent_id', JSON.stringify(e.type));
  router.push('/open_intelligent');
};
const gocopyright = () => {
  router.push('/copyright');
};
const privileges = [
  {
    id: 1,
    logo: require('@/assets/img/logo1.png'),
    name: lang.v.name1,
    describe: lang.v.describe1,
  },
  {
    id: 2,
    logo: require('@/assets/img/logo2.png'),
    name: lang.v.name2,
    describe: lang.v.describe2,
  },
  {
    id: 3,
    logo: require('@/assets/img/logo3.png'),
    name: lang.v.name3,
    describe: lang.v.describe3,
  },
  {
    id: 4,
    logo: require('@/assets/img/logo4.png'),
    name: lang.v.name4,
    describe: lang.v.describe4,
  },
];
const userinfo = ref({});
const gointelligent = () => {
  userinfo.value = dataCenter.user.data;
  if (userinfo.value == null) {
    router.push('/intelligent');
  } else {
    if (
      userinfo.value.talentCardType == null ||
      userinfo.value.talentCardType == 0
    ) {
      router.push('/intelligent');
    } else {
      router.push('/become_intelligent');
    }
  }
};

const pages = reactive({
  status: 1,
  page: 1,
  limit: 2,
});

const downloadApp = () => {
  window.open(window.download.ios, '_blank');
};
const downloadApp_2 = () => {
  window.open(window.download.android, '_blank');
};
const types_login = ref([]);
// 众筹剧集相关
// 据点支付弹窗
const payCard = ref({});
const PayDialog = ref(null);
const isshow = e => {
  payCard.value = e;
  PayDialog.value.open();
};
const addcode = reactive({
  parentInvitationCode: '',
});
const invite_info = ref();

onMounted(async () => {
  await personal().then(res => {
    if (!res) {
      clearLocalUser();
    } else {
      userinfo.value = res;
      dataCenter.user.save(res);
    }
  });
  await inviteInfo().then(res => {
    show_dia.inited = true;
    if (!res) {
      return;
    }
    invite_info.value = res;
    if (invite_info.value.parentInvitationCode == null) {
      show_dia.show = true;
      addcode.parentInvitationCode = '';
    } else {
      show_dia.show = false;
    }
  });
  sessionStorage.setItem('intelligent_id', JSON.stringify(goldId.value));
  userinfo.value = dataCenter.user.data;
  goldId.value = JSON.parse(sessionStorage.getItem('intelligent_id'));
  await card_info().then(async res => {
    types_login.value = res;
  });
  await card_pay().then(res => {
    text1.value = [...res][0].list[0].price;
    text2.value = [...res][1].list[0].price;
  });
  await invest(pages).then(res => {
    projects.value = res;
  });
});
</script>
<style lang="less" scoped>
.out {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow-y: scroll;
}
.nav {
  width: 100%;
  height: 0;
  padding-bottom: 72%;
  background: url('~@/assets/img/banner_other.png') no-repeat;
  background-position: center;
  background-size: 100% auto;
}

:deep(.el-input-number) {
  width: 100%;
  height: 0.46rem;
  border-radius: 0.06rem;

  .el-input-number__decrease {
    display: none;
  }

  .el-input-number__increase {
    display: none;
  }

  .el-input {
    border: none !important;
    box-shadow: none !important;
    width: 100%;
    height: 0.46rem;

    .el-input__wrapper {
      padding: 0rem;
      width: 100%;
      height: 0.46rem;
      display: flex;
      justify-content: left;
      border: none !important;
      background-color: #f6f6f8;
      box-shadow: none !important;

      .el-input__inner {
        display: flex;
        border: none !important;
        box-shadow: none !important;
        justify-content: left;
        text-align: left;
        padding-left: 0.1rem;
      }
    }
  }
}

.modole {
  display: flex;
  margin-left: 0.14rem;
  margin-top: 0.14rem;

  .stick {
    margin-top: 0.05rem;
    width: 0.02rem;
    height: 0.12rem;
    background: #ff5f5b;
    border-radius: 0.06rem;
    opacity: 1;
  }

  .title {
    height: 0.22rem;
    font-size: 0.16rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #59390f;
    line-height: 0.22rem;
    margin-left: 0.06rem;
  }

  .more_more {
    height: 0.2rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #ed931e;
    line-height: 0.19rem;
  }

  .more_img {
    margin-top: -0.06rem;
    width: 0.2rem;
    height: 0.2rem;
    img {
      width: 100%;
    }
  }
}

.main {
  width: 92.5%;
  margin: auto;
  margin-top: 0.12rem;
  padding-top: 0.14rem;
  padding-bottom: 0.14rem;

  .tab {
    width: 91.93%;
    height: 0.44rem;
    background: rgba(133, 140, 159, 0.06);
    margin: auto;
    border-radius: 0.06rem;
    display: flex;

    .ordinary {
      width: 46.71%;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      font-size: 0.13rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #858c9f;
      margin-left: 2.19%;
      margin-top: 0.07rem;
    }

    .act-ordinary {
      width: 46.71%;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      font-size: 0.13rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #444444;
      margin-left: 2.19%;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.08rem 0rem rgba(133, 140, 159, 0.15);
      border-radius: 0.04rem;
      margin-top: 0.07rem;
    }
  }

  .become_login {
    margin: auto;
    margin-top: 0.04rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    .type_login {
      width: 48.42%;
      padding-bottom: 0.06rem;
      background-color: #ffffff;
      border-radius: 0.08rem;

      .type_top {
        width: 100%;
        height: 0.08rem;
      }

      .card_login {
        .card_logo {
          width: 0.3rem;
          height: 0.3rem;
          margin: auto;
          margin-top: 0.1rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .card_name {
          font-size: 0.2rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #5eb15c;
          text-align: center;
          line-height: 0.28rem;
        }
      }

      .multiple {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.16rem;
        text-align: center;
        margin-top: 0.2rem;
      }

      .Unlock {
        width: 88.1%;
        height: 0.3rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.3rem;
        margin: auto;
        text-align: center;
        margin-top: 0.1rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .task {
        padding-left: 0.06rem;
        padding-right: 0.06rem;
        margin-top: 0.2rem;

        .completion {
          display: flex;
          justify-content: left;

          .complete {
            width: 0.2rem;
            height: 0.2rem;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .points {
            width: 0.04rem;
            height: 0.04rem;
            border-radius: 50%;
            margin-top: 0.07rem;
          }

          .task_describe {
            width: 1.46rem;
            text-align: justify;
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 0.2rem;
            margin-left: 0.06rem;
          }
        }

        p {
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #858c9f;
          line-height: 0.2rem;
          margin-left: 0.26rem;
        }
      }
    }

    .type_login:nth-child(1) {
      .type_top {
        background: #5eb15c;
        border-radius: 0.06rem 0.06rem 0rem 0rem;
      }
    }

    .type_login:nth-child(2) {
      .type_top {
        background: #ed931e;
        border-radius: 0.06rem 0.06rem 0rem 0rem;
      }
    }
  }
}

.xingka {
  width: 90.4%;
  font-size: 0.14rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #858c9f;
  line-height: 0.2rem;
  text-align: justify;
  margin: auto;
  margin-top: 0.12rem;
}

.new_box {
  width: 92.5%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 0.06rem;
  margin-bottom: 0.14rem;

  .new_name {
    height: 0.18rem;
    font-size: 0.13rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #59390f;
    line-height: 0.15rem;
    margin-left: 0.1rem;
    padding-top: 0.1rem;
  }

  .new_contant {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    font-size: 0.12rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #858c9f;
    line-height: 0.2rem;
    margin-top: 0.06rem;

    .new_text {
      padding-bottom: 0.1rem;
    }
  }
}

.main_body {
  width: 92.5%;
  padding-bottom: 0.16rem;
  margin: auto;

  .project {
    width: 100%;
    padding-bottom: 0.12rem;
    background: #ffffff;
    border-radius: 0.06rem;
    opacity: 1;
    margin-top: 0.16rem;

    .project_top {
      width: 100%;
      height: 1.6rem;
      position: relative;
      overflow: hidden;
      border-radius: 0.06rem;

      .image-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        // margin: 0.1rem;
        border-radius: 0.06rem;

        .image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
          border-radius: 0.06rem;

          img {
            width: 100%;
            border-radius: 0.06rem;
          }
        }
      }

      .project_name {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #444444;
        line-height: 0.2rem;
        margin-left: 0.14rem;
        padding-top: 0.14rem;
      }
    }

    .name_time {
      position: relative;
      margin-top: -0.25rem;
      height: 0.2rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.2rem;
    }

    .project_info {
      table {
        margin-top: 0.12rem;
        margin-left: 0.14rem;

        .first_info {
          td:nth-child(1) {
            width: 1rem;
            height: 0.34rem;
            font-size: 0.24rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            line-height: 0.34rem;
            background: linear-gradient(90deg, #f46b45 0%, #eea849 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          td:nth-child(2) {
            width: 0.8rem;
            height: 0.22rem;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #444444;
            line-height: 0.22rem;
            padding-top: 0.06rem;
          }

          td:nth-child(3) {
            width: 1.2rem;
            height: 0.2rem;
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #444444;
            line-height: 0.2rem;
            padding-top: 0.09rem;
            overflow: hidden;

            .text {
              margin: 0;
              width: 1.2rem;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        .second_info {
          height: 0.17rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #858c9f;
          line-height: 0.17rem;
        }
      }
    }

    .tishi {
      height: 0.17rem;
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #858c9f;
      line-height: 0.14rem;
      padding-left: 0.14rem;
      padding-top: 0.12rem;
    }

    .project_foot {
      display: flex;
      justify-content: space-between;
      margin-top: 0.16rem;

      :deep(.van-progress) {
        .van-progress__portion {
          background: linear-gradient(to right, #f46b45, #eea849) !important;
        }
      }

      .project_schedule {
        margin-left: 0.14rem;

        .schedule {
          width: 200%;
        }

        .src_schedule {
          height: 0.17rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ed931e;
          line-height: 0.14rem;
          margin-top: 0.08rem;
        }
      }

      .project_check {
        width: 0.96rem;
        height: 0.34rem;
        background: #ed931e;
        border-radius: 0.61rem;
        opacity: 1;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.34rem;
        text-align: center;
        margin-right: 0.14rem;
      }

      .project_checked {
        width: 0.96rem;
        height: 0.34rem;
        background: #444444;
        border-radius: 0.61rem;
        opacity: 1;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.34rem;
        text-align: center;
        margin-right: 0.14rem;
      }
    }
  }

  .over {
    width: 100%;
    height: 1.67rem;
    background: #ffffff;
    border-radius: 0.06rem;
    opacity: 1;
    margin-top: 0.16rem;

    .project_name {
      height: 0.2rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #c2c5cf;
      line-height: 0.2rem;
      margin-left: 0.14rem;
      padding-top: 0.14rem;
    }

    .project_info {
      table {
        margin-top: 0.06rem;
        margin-left: 0.14rem;

        .first_info {
          td:nth-child(1) {
            width: 30%;
            height: 0.34rem;
            font-size: 0.24rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            line-height: 0.34rem;
            background: #c2c5cf;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          td:nth-child(2) {
            width: 30%;
            height: 0.22rem;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #c2c5cf;
            line-height: 0.22rem;
            padding-top: 0.06rem;
          }

          td:nth-child(3) {
            width: 40%;
            height: 0.2rem;
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #c2c5cf;
            line-height: 0.2rem;
            padding-top: 0.09rem;
          }
        }

        .second_info {
          height: 0.17rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c2c5cf;
          line-height: 0.17rem;
        }
      }
    }

    .project_foot {
      display: flex;
      justify-content: space-between;
      margin-top: 0.16rem;

      .project_schedule {
        margin-left: 0.14rem;

        .schedule {
          :deep(.van-progress) {
            width: 50% !important;

            .van-progress__portion {
              background: #c2c5cf !important;
            }
          }
        }

        .src_schedule {
          height: 0.17rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c2c5cf;
          line-height: 0.14rem;
          margin-top: 0.08rem;
        }
      }

      .project_check {
        width: 0.96rem;
        height: 0.34rem;
        background: #c2c5cf;
        border-radius: 0.61rem;
        opacity: 1;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.34rem;
        text-align: center;
        margin-right: 0.14rem;
      }

      .project_checked {
        width: 0.96rem;
        height: 0.34rem;
        background: #444444;
        border-radius: 0.61rem;
        opacity: 1;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.34rem;
        text-align: center;
        margin-right: 0.14rem;
      }
    }
  }
}

.download {
  width: 92.5%;
  height: 0.56rem;
  display: flex;
  margin: auto;
  margin-top: 0.14rem;

  .box_download {
    width: 48.42%;
    height: 0.56rem;
    display: flex;
    background-color: #ed931e;
    border-radius: 0.06rem;

    .left_logo {
      width: 29.76%;
      height: 0.56rem;
      border-right: 0.01rem solid rgba(255, 255, 255, 0.15);

      .Android {
        width: 0.26rem;
        height: 0.26rem;
        margin: auto;
        margin-top: 0.13rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .right_text {
      width: 70.24%;
      height: 0.56rem;

      .source {
        width: 100%;
        height: 0.28rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
        line-height: 0.4rem;
        margin-left: 0.16rem;
      }

      .type {
        width: 100%;
        height: 0.28rem;
        font-size: 0.13rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.2rem;
        margin-left: 0.16rem;
      }
    }
  }
}

.characteristic {
  width: 92.5%;
  margin: auto;
  margin-top: 0.04rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .box {
    width: 48.42%;
    height: 1.3rem;
    background-color: #ffffff;
    border-radius: 0.06rem;
    margin-top: 0.12rem;

    .characteristic_logo {
      width: 0.62rem;
      height: 0.62rem;
      background-size: 100% 100%;
      margin: auto;
      padding-top: 0.1rem;

      img {
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
      }
    }

    .characteristic_name {
      width: 100%;
      text-align: center;
      margin: auto;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #444444;
    }
  }

  .box:nth-child(2) {
    margin-left: 0.1rem;
  }

  .box:nth-child(4) {
    margin-left: 0.1rem;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 92.53%;
  background-color: #fff;
  border-radius: 0.06rem;
  padding-bottom: 0.14rem;

  .modole {
    display: flex;
    justify-content: space-between;
    margin-left: 0.14rem;
    margin-top: 0.14rem;

    .stick {
      margin-top: 0.07rem;
      width: 0.02rem;
      height: 0.1rem;
      background: #ff5f5b;
      border-radius: 0.06rem;
      opacity: 1;
    }

    .title {
      height: 0.22rem;
      font-size: 0.16rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #59390f;
      line-height: 0.22rem;
      margin-left: 0.06rem;
    }

    .block_top_right {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.06rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .money_join {
    width: 91.93%;
    height: 0.44rem;
    margin: auto;

    input {
      text-indent: 1.5ch;
      width: 100%;
      height: 0.44rem;
      border: none;
      background: #f6f6f8;
      border-radius: 0.06rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      line-height: 0.44rem;
    }

    input::placeholder {
      text-indent: 2ch;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: rgba(133, 140, 159, 0.5);
      line-height: 0.44rem;
    }
  }

  .pay_method {
    width: 91.93%;
    background: #ffffff;
    border-radius: 0.06rem;
    opacity: 1;
    margin: auto;
    margin-top: 0.14rem;
    padding-bottom: 0.14rem;
    border: 0.02rem solid rgba(133, 140, 159, 0.1);

    .title {
      width: 100%;
      height: 0.18rem;
      font-size: 0.13rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #444444;
      line-height: 0.15rem;
      text-align: center;
      margin-top: 0.2rem;
    }

    .payment {
      display: flex;
      justify-content: center;
      margin-top: 0.12rem;

      .box {
        width: 0.88rem;
        height: 0.32rem;
        display: flex;
        justify-content: space-between;
        border-radius: 0.06rem;
        opacity: 1;
        border: 0.01rem solid #5eb15c;

        .pay_logo {
          width: 0.16rem;
          height: 0.16rem;
          margin-left: 0.12rem;
          margin-top: 0.07rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .pay_name {
          height: 0.32rem;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #5eb15c;
          line-height: 0.32rem;
          margin-right: 0.12rem;
        }
      }

      .box:first-child {
        .pay_name {
          margin-right: 0.19rem;
        }
      }

      .box:last-child {
        margin-left: 0.12rem;
      }

      .box_zfb {
        width: 0.88rem;
        height: 0.32rem;
        display: flex;
        justify-content: space-between;
        border-radius: 0.06rem;
        opacity: 1;
        border: 0.01rem solid #1296db;

        .pay_name {
          height: 0.32rem;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #1296db;
          line-height: 0.32rem;
          margin-right: 0.12rem !important;
        }
      }

      .box_none {
        width: 0.88rem;
        height: 0.32rem;
        display: flex;
        justify-content: space-between;
        border-radius: 0.06rem;
        opacity: 1;
        border: 0.01rem solid rgba(133, 140, 159, 0.5);

        .pay_name {
          height: 0.32rem;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: rgba(133, 140, 159, 0.5);
          line-height: 0.32rem;
          margin-right: 0.12rem;
        }
      }
    }

    .pay {
      width: 100%;
      height: 0.18rem;
      font-size: 0.13rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #444444;
      line-height: 0.18rem;
      text-align: center;
      margin-top: 0.16rem;
    }

    .pay_money {
      width: 100%;
      height: 0.34rem;
      font-size: 0.24rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #444444;
      line-height: 0.34rem;
      text-align: center;
      margin-top: 0.06rem;
    }

    .wx_meet {
      .meet {
        width: 1.26rem;
        height: 1.26rem;
        margin: auto;
        margin-top: 0.16rem;
        background-color: #5eb15c;
      }

      .agreement {
        margin-top: 0.16rem;
        padding-bottom: 0.2rem;

        p {
          width: 100%;
          text-align: center;
          font-size: 0.12rem;
          margin: 0;
        }

        p:nth-child(2) {
          color: rgba(94, 177, 92, 1);
        }
      }
    }

    .zfb_meet {
      .fork {
        width: 33.23%;
        height: 0.32rem;
        margin: auto;
        margin-top: 0.16rem;
        display: flex;
        justify-content: center;
        background-color: rgba(237, 147, 30, 1);
        border-radius: 0.06rem;

        .skip {
          width: 0.16rem;
          height: 0.16rem;

          img {
            width: 100%;
            height: 100%;
            margin-top: 0.08rem;
          }
        }

        .text {
          height: 0.32rem;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.32rem;
          margin-left: 0.06rem;
        }

        :deep(.van-loading__spinner) {
          margin-top: 0.03rem;
        }
      }

      .agreement {
        margin-top: 0.16rem;
        padding-bottom: 0.2rem;

        p {
          width: 100%;
          text-align: center;
          font-size: 0.12rem;
          margin: 0;
        }

        p:nth-child(2) {
          color: rgba(237, 147, 30, 1);
        }
      }
    }
  }
}

::v-deep .van-dialog {
  .van-dialog__header {
    text-align: left !important;
    margin-left: 0.2rem;
    font-size: 0.16rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
  }
}

.dialog-box {
  width: 2.6rem;
  margin: auto;
  margin-top: 0.12rem;
  margin-bottom: 0.12rem;

  input {
    color: #333;
    width: 2.6rem;
    height: 0.44rem;
    line-height: 0.44rem;
    border-radius: 0.1rem;
    border: none;
    font-size: 0.14rem;
    padding-left: 0.1rem;
    background-color: rgba(246, 247, 249, 1);
  }

  input::-webkit-input-placeholder {
    font-size: 0.14rem;
    color: rgba(133, 140, 159, 0.5);
  }
}
</style>
